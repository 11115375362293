import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

const Header = Styled.header`
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
`
const BgImage = Styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "100vh")};
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }

  @media screen and (max-width: 600px) {
    height: ${({ mobileHeight }) =>
      mobileHeight ? `${mobileHeight}px` : "100vh"};
  }
`
const Hero = Styled(Img)`
  position: absolute !important;
  width: 4.5rem;
  top: ${({ height }) => (height ? `${height}px` : "100vh")};
  left: 50%;
  transform: translate(-50%, -50%);
`
const Content = Styled.div`
  position: absolute;
  top: ${({ height }) => (height ? `${height / 2}px` : "50vh")};
  padding: 1rem;
  text-transform: uppercase;
  font-size: 3rem;
  color: ${({ theme }) => theme.primaryLight};
  @media all and (max-width: 500px) {
    font-size: 2rem;
  }
`
const ContentImg = Styled(Img)`
  z-index: 101;
  position: absolute !important;
  top: ${({ height }) => (height ? `${height / 3}px` : "33vh")};
  right: ${({ height }) => (height ? `50%` : "50%")};
  margin-right: ${({ height }) => (height ? `-${height / 2 / 2}px` : "-20vw")};
  width: ${({ height }) => (height ? `${height / 2}px` : "40vw")};
`

const MyHeader = ({ fluid, alt, height, heightMobile, h1, img }) => {
  const data = useStaticQuery(graphql`
    query headerQuery {
      hero: allFile(filter: { name: { eq: "hex-hero" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const hero = data.hero.edges[0].node.childImageSharp.fluid
  if (fluid) {
    return (
      <Header>
        <BgImage
          fluid={fluid}
          alt={alt}
          height={height}
          heightMobile={heightMobile}
        />
        {/* <Hero
          fluid={hero}
          alt="Hero"
          height={height}
          imgStyle={{ objectFit: "contain" }}
        /> */}
        {img ? (
          <ContentImg
            height={height}
            fluid={img}
            imgStyle={{ objectFit: "contain" }}
          />
        ) : (
          <Content height={height}>{h1}</Content>
        )}
      </Header>
    )
  }
}

MyHeader.propTypes = {
  alt: PropTypes.string.isRequired,
  fluid: PropTypes.object.isRequired,
  height: PropTypes.number,
  heightMobile: PropTypes.number,
  children: PropTypes.node,
}

export default MyHeader
