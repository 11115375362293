import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { ToastProvider } from "../Toasts"
import { useDarkMode } from "../Hooks"

// Master CSS Import
import "../../styles/styles.css"
// Styled Components Theme/Global
import { GlobalStyles, DefaultTheme, DarkTheme } from "../../styles"

import Nav from "../Nav"
import Header from "../Header"
import AnimatedHeader from "../Header/AnimatedHeader"
import Footer from "../Footer"

const Layout = ({ fluid, alt, height, heightMobile, h1, img, children }) => {
  useEffect(() => {
    // remove dim on page change
    if (
      document.getElementById("dimmer-overlay").classList.contains("dimmer")
    ) {
      document.getElementById("dimmer-overlay").classList.toggle("dimmer")
    }
  }, [])
  const [isDark, toggleIsDark] = useDarkMode()
  return (
    <ThemeProvider theme={isDark ? DarkTheme : DefaultTheme}>
      <GlobalStyles />
      <Nav isDark={isDark} toggleIsDark={toggleIsDark} />
      {/* <Header
        fluid={fluid}
        alt={alt}
        height={height}
        heightMobile={heightMobile}
        h1={h1}
        img={img}
      /> */}
      <AnimatedHeader
        height={height}
        heightMobile={heightMobile}
        h1={h1}
        img={img}
      />
      <ToastProvider>
        <div className="container-fluid mt-5">
          <main>{children}</main>
        </div>
      </ToastProvider>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
