import React, { useEffect, useState, useRef } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

import { useOnClickOutside, useScrollPosition } from "../Hooks"
import Burger from "./Burger"
import Menu from "./Menu"

import { URLS } from "../../config"
import ExternalLink from "../ExternalLink"
import {
  Linkedin,
  AdjustDark,
  AdjustLight,
} from "../../components/Common/fontawesome"

const Nav = Styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 110;
  display: flex;
  justify-content: space-between;
  height: 60px;
  transition: all 250ms ease 0ms;
  background-color: ${({ show, theme }) =>
    show ? theme.background : theme.onBackground};
  box-shadow: ${({ show }) =>
    show ? "0 0 5px 0 rgba(0, 0, 0, 0.15)" : "none"};
`
const NavLeft = Styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  width: 33%;
`
const NavCenter = Styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  width: 33%;
`
const NavRight = Styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 33%;
`
const NavLogo = Styled(Img)`
  width: 40px;
  &:hover {
    opacity: 0.6;
  }
`
const NavItems = Styled.ul`
  display: flex;
  flex-direction: row;
  z-index: 99;
  li {
    position: relative;
    list-style: none;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    height: 100%;
    margin-right: 10px;
    svg {
      width: 1.1rem;
      color: ${({ show, theme }) => (show ? theme.onSurface : theme.surface)};
      &:hover {
        color: ${({ show, theme }) => (show ? theme.primary : theme.primary)};
        opacity: 0.6;
      }
    }
  }
`

const MyNav = ({ isDark, toggleIsDark }) => {
  const data = useStaticQuery(graphql`
    query navQuery {
      logoBlack: allFile(filter: { name: { eq: "kd_icon_black_500x500" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
      logoWhite: allFile(filter: { name: { eq: "kd_icon_white_500x500" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const [isScrolled, setIsScrolled] = useState(false)
  const [open, setOpen] = useState(false)
  const node = useRef()
  useOnClickOutside(node, () => {
    if (
      document.getElementById("dimmer-overlay").classList.contains("dimmer")
    ) {
      document.getElementById("dimmer-overlay").classList.toggle("dimmer")
      setOpen(false)
    }
  })
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -50) {
        setIsScrolled(true)
      }
      if (currPos.y > -50) {
        setIsScrolled(false)
      }
    },
    [isScrolled],
    null,
    false,
    300
  )
  useEffect(() => {
    document.addEventListener("keydown", escFunction)
    return () => {
      document.removeEventListener("keydown", escFunction)
    }
  }, [])

  const escFunction = e => {
    if (e.keyCode === 27) {
      if (
        document.getElementById("dimmer-overlay").classList.contains("dimmer")
      ) {
        document.getElementById("dimmer-overlay").classList.toggle("dimmer")
        setOpen(false)
      }
    }
  }
  const toggle = () => {
    setOpen(!open)
    document.getElementById("dimmer-overlay").classList.toggle("dimmer")
  }
  const renderLogo = () => {
    if (isScrolled && isDark) {
      return (
        <NavLogo
          fluid={logoWhite}
          alt="KM White Logo"
          imgStyle={{ objectFit: "contain" }}
        />
      )
    }
    if (!isScrolled && isDark) {
      return (
        <NavLogo
          fluid={logoBlack}
          alt="KM Black Logo"
          imgStyle={{ objectFit: "contain" }}
        />
      )
    }
    if (isScrolled && !isDark) {
      return (
        <NavLogo
          fluid={logoBlack}
          alt="KM Black Logo"
          imgStyle={{ objectFit: "contain" }}
        />
      )
    }
    if (!isScrolled && !isDark) {
      return (
        <NavLogo
          fluid={logoWhite}
          alt="KM White Logo"
          imgStyle={{ objectFit: "contain" }}
        />
      )
    }
  }

  const logoBlack = data.logoBlack.edges[0].node.childImageSharp.fluid
  const logoWhite = data.logoWhite.edges[0].node.childImageSharp.fluid
  return (
    <Nav show={isScrolled} isDark={isDark}>
      <NavLeft ref={node}>
        <Burger open={open} setOpen={toggle} show={isScrolled} />
        <Menu open={open} setOpen={toggle} isDark={isDark} />
      </NavLeft>
      <NavCenter>
        <Link to="/">{renderLogo()}</Link>
      </NavCenter>
      <NavRight>
        <NavItems show={isScrolled}>
          <li>
            <ExternalLink href={URLS.LINKEDIN}>
              <Linkedin className="mr-2" />
            </ExternalLink>
          </li>
          <li>
            {isDark ? (
              <span onClick={() => toggleIsDark(!isDark)}>
                <AdjustDark className="mr-2" />
              </span>
            ) : (
              <span onClick={() => toggleIsDark(!isDark)}>
                <AdjustLight className="mr-2" />
              </span>
            )}
          </li>
        </NavItems>
      </NavRight>
    </Nav>
  )
}

export default MyNav
