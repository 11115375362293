const URLS = {
  TESTING: `http://localhost:3090`,
  CONTACT: `/.netlify/functions/contact`,
  FACEBOOK: `https://www.facebook.com/bodybykickbush`,
  INSTAGRAM: `https://www.instagram.com/bodybykickbush`,
  LINKEDIN: `https://www.linkedin.com/in/adam-kickbush-0741261a/`,
}

const STRIPE = {
  TESTING: ``,
  LIVE: ``,
}

const ROUTES = [
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Projects",
    path: "/projects",
  },
  {
    name: "Contact",
    path: "/contact",
  },
  {
    name: "FAQ",
    path: "/faq",
  },
]

const SKILLS = [
  {
    skill: `CSS`,
    value: `85`,
  },
  {
    skill: `HTML`,
    value: `95`,
  },
  {
    skill: `JavaScript`,
    value: `95`,
  },
  {
    skill: `ReactJS`,
    value: `95`,
  },
  {
    skill: `NodeJS`,
    value: `95`,
  },
  {
    skill: `Python`,
    value: `75`,
  },
  {
    skill: `Go`,
    value: `30`,
  },
  {
    skill: `C++`,
    value: `30`,
  },
  {
    skill: `Photoshop`,
    value: `85`,
  },
  {
    skill: `Illustator`,
    value: `75`,
  },
]
const INSTAFEED = {
  USER: ``,
  CLIENT: ``,
  TOKEN: ``,
}
const FACEBOOK = {
  PIXEL: ``,
}

const GOOGLE = {
  TOKEN: ``,
}

const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tabletS: "480px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

const DEVICES = {
  maxMobileS: `(max-width: ${SIZE.mobileS})`,
  maxMobileM: `(max-width: ${SIZE.mobileM})`,
  maxMobileL: `(max-width: ${SIZE.mobileL})`,
  maxTabletS: `(max-width: ${SIZE.tabletS})`,
  maxTablet: `(max-width: ${SIZE.tablet})`,
  maxLaptop: `(max-width: ${SIZE.laptop})`,
  maxLaptopL: `(max-width: ${SIZE.laptopL})`,
  maxDesktop: `(max-width: ${SIZE.desktop})`,
  maxDesktopL: `(max-width: ${SIZE.desktop})`,
  minMobileS: `(min-width: ${SIZE.mobileS})`,
  minMobileM: `(min-width: ${SIZE.mobileM})`,
  minMobileL: `(min-width: ${SIZE.mobileL})`,
  minTabletS: `(min-width: ${SIZE.tabletS})`,
  minTablet: `(min-width: ${SIZE.tablet})`,
  minLaptop: `(min-width: ${SIZE.laptop})`,
  minLaptopL: `(min-width: ${SIZE.laptopL})`,
  minDesktop: `(min-width: ${SIZE.desktop})`,
  minDesktopL: `(min-width: ${SIZE.desktop})`,
}

export {
  URLS,
  STRIPE,
  ROUTES,
  SKILLS,
  INSTAFEED,
  FACEBOOK,
  GOOGLE,
  DEVICES,
  SIZE,
}
