import React from "react"
import Styled from "styled-components"
import Img from "gatsby-image"
import Particles from "react-particles-js"

import { Hex } from "./particles"

const Container = Styled.div`
    background-color: #121212;
    width: 100%;
    min-height: ${({ height }) => (height ? `${height}px` : "100vh")};
    overflow: hidden;
`
const Content = Styled.div`
  position: absolute;
  top: ${({ height }) => (height ? `${height / 2}px` : "50vh")};
  padding: 1rem;
  text-transform: uppercase;
  font-size: 3rem;
  color: ${({ theme }) => theme.primaryLight};
  @media all and (max-width: 500px) {
    font-size: 2rem;
  }
`
const ContentImg = Styled(Img)`
  z-index: 100;
  position: absolute !important;
  top: 20vh;
  right: 50%;
  margin-right: -15vw;
  width: 30vw;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    top: 30vh;
    margin-right: -25vw;
    width: 50vw;
  }
`

const AnimatedHeader = ({ height, heightMobile, h1, img }) => (
  <Container height={height} heightMobile={heightMobile}>
    <Particles params={Hex} height="100vh" />
    {img ? (
      <ContentImg fluid={img} imgStyle={{ objectFit: "contain" }} />
    ) : (
      <Content height={height}>{h1}</Content>
    )}
  </Container>
)

export default AnimatedHeader
