import { SIZE } from "../config"

const DefaultTheme = {
  primary: "#00AEEF", // blue
  primaryVariant: "#009DD6", // darker blue
  secondary: "#E31B6D", // pink
  background: "#FFFFFF", // white
  offBackground: "#f5f5f5", // light grey
  surface: "#FFFFFF", // white
  error: "#B00020", // red
  onPrimary: "#FFFFFF", // white
  onSecondary: "#000000", // black
  onBackground: "#000000", // black
  onSurface: "#000000", // black
  onError: "#FFFFFF", // white
  input: "#b2b6b8", // light grey
  mobile: SIZE.mobileL,
  fontPrimary: `"Roboto-Regular", sans-serif, Helvetica Neue, Helvetica, Arial,
  sans-serif !important;`,
  fontSecondary: `"SourceCodePro-Regular", sans-serif, Helvetica Neue, Helvetica,
  Arial, sans-serif !important;`,
}

const DarkTheme = {
  primary: "#00B9FC", // lighter blue
  primaryVariant: "#009DD6", // darker blue
  secondary: "#E31B6D", // pink
  background: "#121212", // dark grey
  offBackground: "#f5f5f5", // light grey
  surface: "#121212", // dark grey
  error: "#CF6679", // pinkish
  onPrimary: "#000000", // black
  onSecondary: "#000000", // black
  onBackground: "#FFFFFF", // white
  onSurface: "#FFFFFF", // white
  onError: "#000000", // black
  input: "#1E242C", // dark off blue grey
  mobile: SIZE.mobileL,
  fontPrimary: `"Roboto-Regular", sans-serif, Helvetica Neue, Helvetica, Arial,
  sans-serif !important;`,
  fontSecondary: `"SourceCodePro-Regular", sans-serif, Helvetica Neue, Helvetica,
  Arial, sans-serif !important;`,
}

export { DefaultTheme, DarkTheme }
